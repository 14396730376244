<template>
  <category-risk
    category="awareness"
    title="Security Awareness & Training"
    :banner="banner"
  />
</template>

<script>
  export default {
    name: 'DashboardAwarenessTraining',

    data: () => ({
      banner: {
        title: 'What is Security Awareness & Training?',
        body: "Security awareness training is a formal process for educating employees about computer security."
      }
    }),

    components: {
      CategoryRisk: () => import('../component/Category'),
    }
  }
</script>
